import * as Sentry from '@sentry/nextjs'
import { Service, Product } from '../interfaces'

export function formatNumber(amount: number) {
  return (amount / 100).toLocaleString('en-GB')
}

export function startCheckout({
  service,
  type,
  items = [],
  props = {},
}: {
  service: Service | Product
  type: any
  items?: any[]
  props?: any
}) {
  try {
    const klayValue = {
      $event_id: props.user
        ? `${props.user.substring(0, 7)}_${Date.now()}`
        : `${Math.floor(1000000 + Math.random() * 900000)}_${Date.now()}`,
      $value: props.total / 100,
      ItemNames: items.map((x) => x.item.title),
      CheckoutURL: 'https://charmelle.london/booking',
      Categories: items.map((x) => x.item.category.name),
      Items: items.map((x) => {
        console.log('ITEM', x)
        const image = x.item.media.filter((y) => y.asset.type.value === 'IMAGE')
        const option = x.item.pricing.find((y) => y.data.id === x.option)
        const subOption = x.subOption
          ? option.data.options.find((y) => y.id === x.subOption)
          : null

        return {
          ProductName: x.item.title,
          ProductID: x.item.id,
          Categories: [x.item.category.name],
          ImageURL: image[0].asset.url.startsWith('https')
            ? image[0].asset.url
            : `https://charmelle.london${image[0].asset.url}`,
          URL: window.location.href,
          Brand: 'Charmelle London',
          Price: subOption
            ? subOption.data.price / 100
            : option.data.price / 100,
        }
      }),
    }
    // @ts-ignore
    klaviyo && klaviyo.track('Started Checkout', klayValue)
    console.log('klayValue', klayValue)
  } catch (err) {
    console.log('err', err)
    Sentry.captureException(err)
  }
}

export function klayAction({
  service,
  type,
  items = [],
  props = {},
}: {
  service: Service | Product
  type: any
  items?: any[]
  props?: any
}) {
  console.log('klayAction', service, type, items, props)

  let value = {}

  if (type === 'view' && service) {
    const image = service.media.filter((x) => x.asset.type.value === 'IMAGE')
    const pricing = service.pricing[service.pricing.length - 1]
    value = {
      ProductName: service.title,
      ProductID: service.id,
      Categories: [service.category.name],
      ImageURL: image[0].asset.url.startsWith('https')
        ? image[0].asset.url
        : `https://charmelle.london${image[0].asset.url}`,
      URL: window.location.href,
      Brand: 'Charmelle London',
      Price: pricing.data.price / 100,
    }
  }

  if (type === 'add' && service) {
    const image = service.media.filter((x) => x.asset.type.value === 'IMAGE')
    const pricing = service.pricing[service.pricing.length - 1]
    value = {
      AddedItemProductName: service.title,
      AddedItemProductID: service.id,
      AddedItemCategories: [service.category.name],
      AddedItemImageURL: image[0].asset.url.startsWith('https')
        ? image[0].asset.url
        : `https://charmelle.london${image[0].asset.url}`,
      AddedItemURL: window.location.href,
      AddedItemPrice: pricing.data.price / 100,
      Items: items.map((x) =>
        klayAction({
          service: x.item,
          type: 'view',
        })
      ),
    }
  }

  if (type === 'start') {
    value = {
      $event_id: props.user
        ? `${props.user.substring(0, 7)}_${Date.now()}`
        : `${Math.floor(1000000 + Math.random() * 900000)}_${Date.now()}`,
      $value: props.total / 100,
      ItemNames: items.map((x) => x.item.title),
      CheckoutURL: 'https://charmelle.london/booking',
      Categories: items.map((x) => x.item.category.name),
      Items: items.map((x) =>
        klayAction({
          service: x.item,
          type: 'view',
        })
      ),
    }
  }

  // console.log('Tracking', value);

  return value
}

export function currency(
  amount,
  currency = 'GBP',
  locale = 'en-GB',
  opts = {}
) {
  if (amount === null || amount === undefined) return null
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...opts,
  }).format(amount / 100)
}
