import '../styles/index.css'

// import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader'
import { Amplify } from 'aws-amplify'
import Script from 'next/script'

import { SessionProvider } from '../providers/session'
import { BasketProvider } from '../providers/basket'
import { Main } from '../components/atoms/carry'
import { usePageViews } from '../utils/analytics'

export const CognitoConfig = {
  aws_project_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_cognito_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_COGNITO_ID_POOL,
  ssr: true,
}

Amplify.configure({
  ...CognitoConfig,
  ssr: true,
})

export function reportWebVitals(metric) {
  // @ts-ignore
  window.gtag &&
    window.gtag('event', metric.name, {
      event_category:
        metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(
        metric.name === 'CLS' ? metric.value * 1000 : metric.value
      ),
      event_label: metric.id,
      non_interaction: true,
    })
}

function CharmelleLondon({ Component, pageProps, err }) {
  usePageViews()
  return (
    <>
      <Script strategy="afterInteractive" id="ga-sc">
        {`(function (d, t) {
            var g = d.createElement(t)
            var s = d.getElementsByTagName(t)[0]
            g.async = true
            g.src = 'https://www.googletagmanager.com/gtag/js?id=UA-126290103-1'
            s.parentNode.insertBefore(g, s)
          })(document, 'script');`}
      </Script>
      <Script strategy="afterInteractive" id="gt-sc">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WFV966B');`}
      </Script>
      <Script
        strategy="afterInteractive"
        src="https://www.googleoptimize.com/optimize.js?id=OPT-N8MFQ95"
      />
      <Script strategy="afterInteractive" id="hs-sc">
        {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
      heap.load("3455101916");`}
      </Script>
      <Script strategy="afterInteractive" id="fb-sc">
        {`!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('consent', 'grant')
      fbq('init', '288312672007005');
      fbq('track', 'PageView');`}
      </Script>
      <Script strategy="afterInteractive" id="fs-sc">
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "lrz7uik7v7");`}
      </Script>
      <Script strategy="afterInteractive" id="tt-sc">
        {`!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load('CCLH6UBC77UDI0MAGLF0'); ttq.page(); }(window, document, 'ttq');`}
      </Script>
      <Script
        strategy="lazyOnload"
        src="https://eu-library.klarnaservices.com/lib.js"
        data-client-id="79b45cc9-12bc-556d-a492-1566e1463d58"
      />
      <Script
        strategy="lazyOnload"
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UUDnxX"
      />
      <Script strategy="afterInteractive" id="klay">
        {`
        !function() {
          if (!window.klaviyo) {
            window._klOnsite = window._klOnsite || [];
            try {
              window.klaviyo = new Proxy({}, {
                get: function(n, i) {
                  return "push" === i ? function() {
                    var n;
                    (n = window._klOnsite)
                    .push.apply(n, arguments)
                  } : function() {
                    for (var n = arguments.length, o = new Array(n), w = 0; w < n; w++) o[w] = arguments[w];
                    var t = "function" == typeof o[o.length - 1] ? o.pop() : void 0,
                      e = new Promise((function(n) {
                        window._klOnsite.push([i].concat(o, [function(i)
                          {
                            t && t(i), n(i)
                        }]))
                      }));
                    return e
                  }
                }
              })
            }
            catch (n) {
              window.klaviyo = window.klaviyo || [], window.klaviyo.push = function() {
                var n;
                (n = window._klOnsite)
                .push.apply(n, arguments)
              }
            }
          }
        }();
        `}
      </Script>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      {/* <LiveChatLoaderProvider
        providerKey="rn8g2h3e"
        provider="intercom"
        idlePeriod={200}
      > */}
        <SessionProvider>
          <BasketProvider>
            <Component {...pageProps} err={err} />
          </BasketProvider>
        </SessionProvider>
        {/* <Intercom /> */}
      {/* </LiveChatLoaderProvider> */}
      <Main />
      {/* </Suspense> */}
    </>
  )
}

export default CharmelleLondon
