import * as Sentry from '@sentry/browser'

export enum Conversion {
  'contact' = 'AW-677323245/Y3A2CP_gkf0BEO3D_MIC',
  'newsletter' = 'AW-677323245/pNGnCK2-s_0BEO3D_MIC',
  'signup' = 'AW-677323245/LcBuCOyE8fcBEO3D_MIC',
  'purchase' = 'AW-677323245/bBl8CLyB0vcBEO3D_MIC',
  'callback' = 'AW-677323245/rWwCCOSy0vcBEO3D_MIC'
}

export function recordConversion(params: Gtag.ControlParams) {
  if (window && window.gtag) {
    gtag('event', 'conversion', params);

    (window as any).heap &&
      (window as any).heap.addUserProperties({
        type: 'conversion',
        ...params,
      });

    try {
      // @ts-ignore
      window.FS && window.FS.event('conversion', params);
    } catch (err) {
      console.log(err)
    }
  }
}

export const recordSession = (signInResult) => {
  try {
    Sentry.configureScope(function (scope) {
      scope.setUser({
        id: signInResult.attributes.sub,
        email: signInResult.attributes.email,
      })
    })

    window.gtag && window.gtag('event', 'login', {});

    // @ts-ignore
    window.heap && window.heap.identify(signInResult.attributes.sub)

    // @ts-ignore
    window.heap && window.heap.addUserProperties({
      email: signInResult.attributes.email,
      name: `${signInResult.attributes.given_name} ${signInResult.attributes.family_name}`,
    })

    window.gtag &&
      window.gtag('set', { user_id: signInResult.attributes.sub })

    // @ts-ignore
    window && window.Intercom && window.Intercom('boot', {
      app_id: 'rn8g2h3e',
      email: signInResult.attributes.email,
      name: `${signInResult.attributes.given_name} ${signInResult.attributes.family_name}`,
      user_id: signInResult.attributes.sub,
    })
  } catch (err) {
    Sentry.captureException(err)
    console.log(err)
  }
}

export const recordSignup = (signInResult) => {
  try {
    console.dir(signInResult, { depth: null })
    Sentry.configureScope(function (scope) {
      scope.setUser({
        id: signInResult.attributes.sub,
        email: signInResult.attributes.email,
      })
    })

    // @ts-ignore
    window.heap && window.heap.identify(signInResult.attributes.sub)

    // @ts-ignore
    window.heap && window.heap.addUserProperties({
      email: signInResult.attributes.email,
      name: `${signInResult.attributes.given_name} ${signInResult.attributes.family_name}`,
    })

    // @ts-ignore
    window.fbq && window.fbq('track', 'CompleteRegistration')

    window.gtag &&
      window.gtag("event", "sign_up")

    window.gtag &&
      window.gtag('set', { user_id: signInResult.attributes.sub })
    
    window.gtag &&
      window.gtag('set', 'user_id', signInResult.attributes.sub)

    recordConversion({
      send_to: Conversion.signup,
      // @ts-ignore
      value: 1,
      currency: "GBP",
    });

    try {
      // @ts-ignore
      window.FS && window.FS.event('CompleteRegistration', { user_id: signInResult.attributes.sub });
    } catch (err) {
      console.log(err)
    }

    // @ts-ignore
    window && window.Intercom && window.Intercom('boot', {
      app_id: 'rn8g2h3e',
      email: signInResult.attributes.email,
      name: `${signInResult.attributes.given_name} ${signInResult.attributes.family_name}`,
      user_id: signInResult.attributes.sub,
    })
  } catch (err) {
    Sentry.captureException(err)
    console.log(err)
  }
}
